import { useRef, useState, useEffect } from "react";
import "./styles.css";
import { motion, useElementScroll, useTransform } from "framer-motion";

export default function App() {
    // Scroll progress of a scrollable element
    const ref = useRef();
    const { scrollYProgress, scrollY } = useElementScroll(ref);

    // Converting ‘scrollYProgress’ to other values
    const borderRadius = useTransform(
        scrollYProgress,
        [0, 0.5, 1],
        [20, 100, 20]
    );
    const left = useTransform(
        scrollYProgress,
        [0, 1],
        ["calc(0vw + 20px)", "calc(100vw + -220px)"]
    );
    const gradient = useTransform(
        scrollYProgress,
        [0, 1],
        [
            "linear-gradient(90deg, #f08 0%, #70f 50%, #09f 100%)",
            "linear-gradient(360deg, rgb(255, 51, 102) 0%, rgb(255, 204, 0) 30%, rgb(0, 204, 136) 100%)"
        ]
    );

    // States for saving a copy of the Motion values that we want to display
    const [currentProgress, setCurrentProgress] = useState(
        scrollYProgress.get().toFixed(2)
    );
    const [currentY, setCurrentY] = useState(0);
    const [currentLeft, setCurrentLeft] = useState(left.get());

    // Updating those states with an onChange()
    useEffect(() => {
        const unsubscribeProgress = scrollYProgress.onChange((value) =>
            setCurrentProgress(value.toFixed(2))
        );
        const unsubscribeY = scrollY.onChange((value) =>
            setCurrentY(Math.round(value))
        );
        const unsubscribeLeft = left.onChange((value) => setCurrentLeft(value));

        return () => {
            unsubscribeProgress();
            unsubscribeY();
            unsubscribeLeft();
        };
    }, []); // the [] makes this only run on mount, which is enough

    return (
        <div className="App">
            <h1>Element scroll</h1>

            <div className="progressCircle">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56">
                    <motion.path
                        d="M 28 5 C 40.703 5 51 15.297 51 28 C 51 40.703 40.703 51 28 51 C 15.297 51 5 40.703 5 28 C 5 15.297 15.297 5 28 5 Z"
                        fill="transparent"
                        strokeWidth="2.05"
                        stroke="#70f"
                        strokeLinecap="round"
                        style={{ pathLength: scrollYProgress }}
                    />
                    <text x="12" y="33" fill="#70f">
                        {currentProgress}
                    </text>
                </svg>
            </div>

            <div className="bottomBar">
                <p className="label">scrollY: {currentY}</p>
            </div>

            <div style={{ height: "50vh", margin: "30px 0px 40px 0px" }}>
                <div
                    ref={ref}
                    style={{
                        height: "100%",
                        overflow: "auto",
                        background: "LightGray",
                        padding: "0 10px"
                    }}
                >
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aliquam at eros eu orci condimentum fermentum. Nam
                        vehicula nunc et nisi pretium, sit amet commodo erat
                        dapibus.
                    </p>
                    <p>
                        Cras eu orci mollis, varius erat at, volutpat dolor.
                        Proin a tortor vel tellus sodales vehicula a at dui. Nam
                        interdum condimentum dolor, quis gravida dolor dapibus
                        ac. Aliquam metus est, sagittis eget convallis eget,
                        ornare a lorem.
                    </p>
                    <p>
                        Fusce sit amet augue nec libero vestibulum fermentum vel
                        in nunc. Cras vulputate at felis nec venenatis.
                        Pellentesque sollicitudin id nisl ac tristique. Nam
                        pellentesque, quam eu egestas ultricies, tellus odio
                        iaculis felis, ut maximus ipsum ligula at purus.
                    </p>
                    <p>
                        Nam scelerisque eget augue sed molestie. Nam et
                        ullamcorper mauris, ac bibendum sem. Aliquam massa ante,
                        aliquam eget velit a, sagittis fringilla magna.
                        Suspendisse felis lacus, mattis in magna ut, porta
                        scelerisque lacus. Nunc eget ultricies libero, eu cursus
                        dui.
                    </p>
                    <p>
                        Vivamus ac leo accumsan, tempor tortor quis, rutrum
                        magna. Aliquam in nibh ex. Morbi et nibh erat. Nulla at
                        eros vitae purus varius egestas.
                    </p>
                    <p>
                        Vestibulum placerat porttitor posuere. Mauris auctor
                        tristique neque, vel blandit ipsum mattis id. Morbi in
                        ipsum et dui vehicula pellentesque.
                    </p>
                    <p>
                        Phasellus convallis ligula sit amet pulvinar
                        sollicitudin. Ut luctus vitae quam in suscipit. Donec
                        auctor feugiat accumsan. Phasellus mollis feugiat
                        tincidunt. Cras ullamcorper metus et commodo lobortis.
                    </p>
                    <p>
                        Etiam erat nulla, maximus vitae hendrerit at, accumsan
                        vitae augue. Sed sit amet diam sit amet dui ullamcorper
                        mattis quis et urna. Ut lectus eros, consequat at
                        rhoncus et, ultricies at nunc.
                    </p>
                    <p>
                        Ut ut urna ligula. Fusce lacinia efficitur enim id
                        ultrices. Mauris sagittis orci et sapien feugiat, eget
                        tincidunt lectus tristique. Cras leo augue, blandit sed
                        ipsum convallis, ultricies dictum felis.
                    </p>
                    <p>
                        Aliquam lobortis tempus lectus, at tincidunt tellus
                        hendrerit ut. Ut pharetra, nisl vitae pretium lobortis,
                        ante nisl pellentesque felis, at lobortis felis nunc
                        vitae libero.
                    </p>
                </div>
            </div>

            <motion.div
                className="box"
                style={{ borderRadius, left, background: gradient }}
            >
                <p className="label">{currentLeft}</p>
            </motion.div>
        </div>
    );
}
